import React from "react"
import { Link } from "gatsby";
import { ModelCard, ModelCardImg, ModelCardTitle} from "./home.styles";

const ModelsCard = ({title, url, image, color, black, grid}) => (
    <ModelCard black={black} className={grid === 5 ? `col-5` : (grid === 3 && `col-3`)}>
      <Link to={url}>
        <ModelCardImg>
          <img src={image} alt={title}/>
        </ModelCardImg>
        <ModelCardTitle color={color}>{title}</ModelCardTitle>
      </Link>
    </ModelCard>
)

export default ModelsCard