import React from "react"
import ModelsCard from "./models-card";
import { Title } from "../../styles/typography.styles";
import { Row, RamBlock } from "./home.styles";
import BtnLink from "../elements/BtnLink";

const Ram = () => {
  return (
    <RamBlock>
      <div className={`container`}>
        <Title withBtn color={'white'}>RAM 1500</Title>
        <BtnLink url={'/ram'} label={'Odkryj RAM 1500'}/>

        <Row black>
          <ModelsCard black grid={5}
                      title="BIGHORN"
                      url="/ram/bighorn"
                      image="/images/home/ram_bighorn.jpg"
                      color="white"/>
          <ModelsCard black grid={5}
                      title="REBEL"
                      url="/ram/rebel"
                      image="/images/home/ram_rebel.jpg"
                      color="white"/>
          <ModelsCard black grid={5}
                      title="LARAMIE"
                      url="/ram/laramie"
                      image="/images/home/ram_laramie.jpg"
                      color="white"/>
          <ModelsCard black grid={5}
                      title="LONGHORN"
                      url="/ram/longhorn"
                      image="/images/home/ram_longhorn.jpg"
                      color="white"/>
          <ModelsCard black grid={5}
                      title="LIMITED"
                      url="/ram/limited"
                      image="/images/home/ram_limited.jpg"
                      color="white"/>
        </Row>
      </div>
    </RamBlock>
  )
}

export default Ram