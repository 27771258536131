import React from "react"
import { Link } from "gatsby";
import styled from "styled-components";

const Btn = styled.div`
  width: 20rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
  
  a {
    width: 20rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
    background-color: ${props => props.theme.colors.primary};
    color: white;
    font-family: ${props => props.theme.fonts.heading};
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color .3s;
    
    &:hover {
      color: white;
      background-color: #B30000;
    }
    
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      background: url("/images/icons/arrow_next.svg") no-repeat center;
      background-size: contain;
      width: 6px;
      height: 1.6rem;
    }
  }
`;

const BtnLink = ({url, label, className}) => (
    <Btn className={className}>
      <Link to={url}>
        {label}
      </Link>
    </Btn>
)

export default BtnLink