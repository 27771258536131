import React from "react"
import { Title } from "../../styles/typography.styles";
import { GridRow, GridImage, GridContent, GridTitle, GridText } from "./home.styles";

const HomeGrid = () => {
  return (
    <>
      <Title>Dodge Challenger</Title>
      <GridRow>
        <div className={`container`}>
          <GridImage>
            <img src="/images/home/2019-dodge-bhp-challenger-block.jpg" alt="Dodge Challenger"/>
          </GridImage>

          <GridContent>
            <GridTitle>Jedyny <br/>
              w swoim rodzaju </GridTitle>
            <div className="line"/>

            <GridText>
              Dodge challenger nadal podnosi poprzeczkę współczesnych pojazdów muskularnych oferując niepowtarzalny wygląd oraz niesamowite osiągi.
            </GridText>
          </GridContent>
        </div>
      </GridRow>

      <div className="container">
        <Title alignLeft>Dodge Charger</Title>
      </div>
      <GridRow alignLeft>
        <div className={`container mob-reverse`}>
          <GridContent>
            <GridTitle>Czterodrzwiowe <br/>
              szaleństwo</GridTitle>
            <div className="line"/>

            <GridText>
              Dodge Charger to maszyna dostarczająca niesamowitą moc, z której dumni są nawet jego poprzednicy.
            </GridText>
          </GridContent>

          <GridImage alignLeft>
            <img src="/images/home/2020-dodge-charger-widebody.jpg" alt="Dodge Charger"/>
          </GridImage>
        </div>
      </GridRow>

      <Title>Dodge Durango</Title>
      <GridRow>
        <div className={`container`}>
          <GridImage>
            <img src="/images/home/2019-dodge-bhp-durango-block.jpg" alt="Dodge Durango"/>
          </GridImage>

          <GridContent>
            <GridTitle>Stawiaj <br/>
              na niezawodność</GridTitle>
            <div className="line"/>

            <GridText>
              Dodge Durango jest potworem o dużej wszechstronności, który Ciebie i Twoich bliskich dowiezie w każde miejsce.
            </GridText>
          </GridContent>
        </div>
      </GridRow>

    </>
  )
}

export default HomeGrid