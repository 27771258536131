import React, {useState, useEffect} from 'react'
import Header from "../components/home/header";
import HomeMain from "../components/home/main";
import Layout from "../components/layout";

export default function Home() {
  // const [init, setInit] = useState(true);
  //
  // useEffect(() => {
  //   setTimeout(() => {
  //     setInit(false);
  //   }, 300)
  // }, [])

  return (
      <Layout title={'Dealer'}>
        <Header/>
        <HomeMain/>
      </Layout>
  )
}
