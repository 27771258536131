import styled from "styled-components";
import { breakpoints } from "./breakpoints.styles";

export const Title = styled.h2`
  color: ${props => props.color};
  font-family: ${props => props.theme.fonts.heading};
  font-size: 3.6rem;
  font-weight: 500;
  text-align: ${props => props.alignLeft ? 'left' : 'center'};
  text-transform: ${props => props.capitalize ? 'none' : 'uppercase'};
  margin-top: 7.2rem;
  margin-bottom: ${props => props.withBtn ? '2.4rem' : '4rem'}; 
  
  ${breakpoints('phone') `
    text-align: center;
  `} 
  
  ${breakpoints('xs') `
    font-size: 2.8rem;
  `} 
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.text};
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  width: 80rem;
  margin-bottom: 8rem;
  
  h3 {
    margin-bottom: 1.6rem;
  }
  
  p {
    margin-bottom: 2rem;
  }
`;