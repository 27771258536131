import React from "react"
import ModelsCard from "./models-card";
import { Title } from "../../styles/typography.styles";
import { Row, DodgeBlock } from "./home.styles";
import BtnLink from "../elements/BtnLink";

const Dodge = () => {
  return (
    <DodgeBlock>
      <div className={`container`}>
        <Title withBtn>Dodge</Title>
        <BtnLink url={'/dodge'} label={'Odkryj Dodge'}/>

        <Row>
          <ModelsCard grid={3}
                      title="Dodge Challenger"
                      url="dodge/challenger"
                      image="/images/home/dodge_trim_challenger.jpg"
                      />
          <ModelsCard grid={3}
                      title="Dodge Charger"
                      url="/dodge/charger"
                      image="/images/home/dodge_trim_charger.jpg"
                      />
          <ModelsCard grid={3}
                      title="Dodge Durango"
                      url="/dodge/durango"
                      image="/images/home/dodge_trim_durango.jpg"
                      />
        </Row>
      </div>
    </DodgeBlock>
  )
}

export default Dodge