import React from "react"
import Models from "./models";
import Ram from "./ram";
import Discover from "./discover";
import Dodge from "./dodge";
import HomeGrid from "./grid";
import About from "./about";
import { Video } from "./home.styles";

const HomeMain = () => (
    <main>
      <Models/>
      <Ram/>
      <Discover/>
      <Dodge/>
      <HomeGrid/>
      <About/>

      <Video className="container">
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/294532871"
                frameBorder="0" allowFullScreen />
      </Video>
    </main>
)

export default HomeMain