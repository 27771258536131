import React from "react"
import ModelsCard from "./models-card";
import { Title } from "../../styles/typography.styles";
import { Row, ModelsBlock } from "./home.styles";

const Models = () => {
  return (
    <ModelsBlock className={`container`}>
      <Title>ODKRYJ NASZE MODELE</Title>

      <Row>
        <ModelsCard title="RAM 1500" url="/ram" image="/images/home/start_trim_ram_thumb.jpg"/>
        <ModelsCard title="DODGE CHALLENGER" url="/dodge/challenger" image="/images/home/start_trim_challenger_thumb.jpg"/>
        <ModelsCard title="DODGE CHARGER" url="/dodge/charger" image="/images/home/start_trim_charger_thumb.jpg"/>
        <ModelsCard title="DODGE DURANGO" url="/dodge/durango" image="/images/home/start_trim_durango_thumb.jpg"/>
      </Row>
    </ModelsBlock>
  )
}

export default Models