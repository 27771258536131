import React from "react"
import { DiscoverBlock, DiscoverImage } from "./home.styles";

const Discover = () => {
  return (
      <DiscoverBlock>
        <DiscoverImage>
          <div className="wrapper">
            <img src="/images/home/start_discover_ram_01.png" alt="Dealer Dodge Ram 1500"/>
          </div>
        </DiscoverImage>
        <DiscoverImage>
          <div className="wrapper">
            <img src="/images/home/start_discover_ram_02.png" alt="Dealer Dodge Ram 1500"/>
          </div>
        </DiscoverImage>
        <DiscoverImage>
          <div className="wrapper">
            <img src="/images/home/start_discover_ram_03.png" alt="Dealer Dodge Ram 1500"/>
          </div>
        </DiscoverImage>
      </DiscoverBlock>
  )
}

export default Discover