import React from "react"
import { AboutContainer, AboutText } from "./home.styles";
import { Title } from "../../styles/typography.styles";
import BtnLink from "../elements/BtnLink";

const About = () => (
    <AboutContainer>
      <Title capitalize>O nas</Title>
      <AboutText>
        Oficjalny salon sprzedaży oraz autoryzowany serwis firmy Dodge oraz RAM w Rzeszowie. <br/>
        Spółka światowej firmy Game Over Cycle produkującej unikatowe motocykle.
      </AboutText>
      <BtnLink label={'więcej'} url={'/salon'}/>
    </AboutContainer>
)

export default About