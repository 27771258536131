import React from "react"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HeaderContainer, Slide, SlideContent, SlideImage, SlideTitle, SlideText } from "./header.styles";

const Header = () => {
  return (
      <HeaderContainer id={`header`}>
        <Carousel showArrows={true} showStatus={false} showThumbs={false} infiniteLoop={true}>
          <Slide>
            <SlideImage>
              <img src="/images/start_banner_01@2x.jpg" alt="Nowy RAM 1500"/>
            </SlideImage>
            <div className="container">
              <SlideContent>
                <SlideTitle>Nowy RAM 1500</SlideTitle>
                <SlideText>2020 RAM 1500 oferuje możliwość holowania przyczepy nawet do 3500 kg.</SlideText>
              </SlideContent>
            </div>
          </Slide>
          <Slide>
            <SlideImage>
              <img src="/images/start_banner_01@2x.jpg" alt="Nowy RAM 1500"/>
            </SlideImage>
            <div className="container">
              <SlideContent>
                <SlideTitle>Nowy RAM 1500</SlideTitle>
                <SlideText>2020 RAM 1500 oferuje możliwość holowania przyczepy nawet do 3500 kg.</SlideText>
              </SlideContent>
            </div>
          </Slide>
        </Carousel>

      </HeaderContainer>
  )
}

export default Header;