import styled from "styled-components"
import { breakpoints } from "../../styles/breakpoints.styles";

export const HeaderContainer = styled.header`
  width: 100%;
  height: 36vw;
  min-height: 24rem;
  margin-top: 16rem;
  
  ${breakpoints('tabLand') `
    margin-top: 8.2rem;
    height: calc(100vh - 8.2rem);
  `} 
  
  .carousel-root,
  .carousel .slider-wrapper, 
  .carousel.carousel-slider,
  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 100%;
  }
  
  .control-arrow {
    width: 5rem;
    height: 7rem;
    background-color: rgba(0, 0, 0, 0.4) !important;
    top: 50% !important;
    transform: translateY(-50%);
    
    &:before {
      width: 1rem;
      height: 2.8rem;
      border: none !important;
    }
  }
  
  .control-prev {
    border-left: 1px solid ${props => props.theme.colors.primary} !important;
    
    &:before {
      background: url("/images/icons/arrow_prev.svg") no-repeat center;
    }
  }
  
  .control-next {
    border-right: 1px solid ${props => props.theme.colors.primary} !important;
    
    &:before {
      background: url("/images/icons/arrow_next.svg") no-repeat center;
    }
  }
  
  .control-dots {
    .dot {
      width: 1.2rem;
      height: 1.2rem;
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: transparent;
      
      &.selected {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        background: rgba(216, 216, 216, 0.5);
      }
    }
  }
`;

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  
  .container {
    position: relative;
    
    ${breakpoints('xs') `
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `} 
  }
`;

export const SlideImage = styled.div`
  width: 100%;
  height: 100%;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SlideContent = styled.div`
  position: absolute;
  bottom: 8rem;
  width: 39rem;
  text-align: left;
  
  ${breakpoints('xs') `
    position: unset;
  `} 
`;

export const SlideTitle = styled.h3`
  color: white;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 4.8rem;
  font-weight: 500;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  position: relative;
  
  ${breakpoints('xs') `
    font-size: 4rem;
  `} 
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 12rem;
    height: 4px;
    background-color: ${props => props.theme.colors.primary}
  }
`;

export const SlideText = styled.div`
  color: white;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.7rem;
  
  ${breakpoints('xs') `
    font-size: 1.6rem;
  `} 
`;